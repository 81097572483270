import React, { useLayoutEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import io from "socket.io-client"
import constant from "../utils/constant"
import { format } from "date-fns"
import OrderStatus from "../utils/OrderStatus"

const SocketClient = ({ refreshOrder }) => {
    const [connected, setConnected] = useState(false)
    const token = constant.TOKEN
    const [lastUpdate, setLastUpdate] = useState(null)

    useLayoutEffect(() => {
        if (!isMobile) {
            const socket = io.connect('https://mob4.rapidleb.com', { transports: ['websocket'], auth: { token: token } })

            socket.on('connect', async () => {
                setConnected(true)
                const now = format(new Date(), 'HH:mm:ss')
                setLastUpdate(now)
            })

            socket.on('disconnect', () => {
                setConnected(false)
            })

            socket.on("order-update", async (message) => {
                refreshOrder({
                    "id": message.id,
                    "customer_number": message.customerNumber,
                    "driver_id": message.driverId,
                    "delivery_location": message.deliveryLocation,
                    "description": message.description || '',
                    "service_charge": message.serviceCharge,
                    "status_id": OrderStatus.find(obj => obj.name === message.status)?.status_id || -1,
                    "driver_response": message.driver_response || '',
                    "driver_location": message.driverLocation,
                    "from_address_id": message.fromAddressId,
                    "to_address_id": message.toAddressId,
                    "created_at": message.createdAt,
                    "updated_at": message.updatedAt,
                    "completed_at": message.completedAt,
                    "pickup_location": message.pickupLocation,
                    "is_manual": {
                        "type": "Buffer",
                        "data": [
                            message.isManual ? 1 : 0
                        ]
                    },
                    "store_id": message.storeId,
                    "is_confirmed": message.isConfirmed,
                    "status_name": message.status,
                    "first_name": message.customerName?.split(' ')[0] || '',
                    "last_name": message.customerName?.split(' ')[1] || '',
                    "store_name": message.storeName,
                    "store_phone": message.sorePhone || '',
                    "customer_first_name": message.customerName?.split(' ')[0] || '',
                    "customer_last_name": message.customerName?.split(' ')[1] || '',
                    "from_address": message.fromAddress || '',
                    "to_address": message.toAddress || '',
                    "created_from_web": message.createdFromWeb || 0,
                    "from_price_list": message.fromPriceList || '',
                    "to_price_list": message.toPriceList || '',
                    "service_rate": message.serviceRate
                })
            })


            return () => {
                socket.disconnect()
                setConnected(false)
            }
        }
        // eslint-disable-next-line
    }, [])

    return (
        !connected
            ? <div className="fixed bottom-2 left-2 z-[203] text-gray-light">
                <div className="flex items-center justify-center p-1">
                    <div className="w-3 h-3 bg-red rounded-full"></div>
                    <div className="px-1 text-red rounded-full">Connecting</div>
                </div>
            </div>
            : <div className="fixed bottom-2 left-2 z-[203] text-gray-light">
                <div className="flex items-center justify-center p-1">
                    <div className="w-3 h-3 bg-green rounded-full"></div>
                    <div className="flex items-center px-1 text-green rounded-full">Connected <span className="text-xs italic text-white-gray px-1">@{ lastUpdate }</span>
                    </div>
                </div>
            </div>
    )
}

export default SocketClient